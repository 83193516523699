var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.type == 'project')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.local_name),expression:"local_name"}],class:_vm.type == 'task' ? 'task_title inputBlur' : 'inputBlur',attrs:{"type":"text"},domProps:{"value":(_vm.local_name)},on:{"blur":_vm.rename_el,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.inputBlur($event)},"input":function($event){if($event.target.composing){ return; }_vm.local_name=$event.target.value}}}):_c('div',{staticClass:"task_rename",class:{'task_rename-chat': _vm.$route.params.chat_id},style:(_vm.taskRenameContainerStyle)},[_c('i',{staticClass:"text-none"},[_vm._v(_vm._s(_vm.local_name))]),(!_vm.$route.params.chat_id)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.local_name),expression:"local_name"}],class:[_vm.type == 'task' ? 'task_title inputBlur2' : 'inputBlur2', _vm.edit ? 'd-block' : 'task-rename-not-active'],attrs:{"spellcheck":false},domProps:{"value":(_vm.local_name)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.inputBlur($event)},"blur":_vm.rename_el,"input":function($event){if($event.target.composing){ return; }_vm.local_name=$event.target.value}}}):_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.local_name),expression:"local_name"}],ref:"insideChatTextarea",class:[_vm.type == 'task' ? 'task_title inputBlur2' : 'inputBlur2', _vm.edit ? 'd-block' : 'task-rename-not-active', {'inside-chat': _vm.$route.params.chat_id}],style:({
            height: _vm.taskRenameContainerStyle.height 
            ? _vm.taskRenameContainerStyle.height.replace('px', '') - 20 < 30 
            ? _vm.taskRenameContainerStyle.height.replace('px', '') + 'px'
            : _vm.taskRenameContainerStyle.height.replace('px', '') - 20 + 'px'
            : undefined
        }),attrs:{"spellcheck":false},domProps:{"value":(_vm.local_name)},on:{"blur":_vm.rename_el,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.inputBlur($event)},"input":function($event){if($event.target.composing){ return; }_vm.local_name=$event.target.value}}}),_c('div',{ref:"taskNameContainer",class:{
            'line-clamp-1': _vm.$route.params.chat_id && _vm.show_task_name_text == 'More',
            'line-clamp-2': !_vm.$route.params.chat_id && _vm.show_task_name_text == 'More',
        },style:({display: !_vm.edit ? 'block' : 'none'}),domProps:{"innerHTML":_vm._s(_vm.urlify(_vm.local_name))},on:{"click":_vm.onEdit}}),_c('span',{on:{"click":_vm.show_task_name}},[_vm._v(_vm._s(_vm.show_task_name_text))])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <input
        v-if="type == 'project'"
        type="text"
        v-model="local_name"
        :class="type == 'task' ? 'task_title inputBlur' : 'inputBlur'"
        @blur="rename_el"
        @keyup.enter.prevent="inputBlur"
    />
    <div v-else class="task_rename" :style="taskRenameContainerStyle" :class="{'task_rename-chat': $route.params.chat_id}">
        <i class="text-none">{{ local_name }}</i>
        <textarea
            v-if="!$route.params.chat_id"
            v-model="local_name"
            :class="[type == 'task' ? 'task_title inputBlur2' : 'inputBlur2', edit ? 'd-block' : 'task-rename-not-active']"
            @keyup.enter.prevent="inputBlur"
            @blur="rename_el"
            :spellcheck="false"
        ></textarea>
        <textarea
            v-else
            v-model="local_name"
            :style="{
                height: taskRenameContainerStyle.height 
                ? taskRenameContainerStyle.height.replace('px', '') - 20 < 30 
                ? taskRenameContainerStyle.height.replace('px', '') + 'px'
                : taskRenameContainerStyle.height.replace('px', '') - 20 + 'px'
                : undefined
            }"
            :class="[type == 'task' ? 'task_title inputBlur2' : 'inputBlur2', edit ? 'd-block' : 'task-rename-not-active', {'inside-chat': $route.params.chat_id}]"
            ref="insideChatTextarea"
            @blur="rename_el"
            @keyup.enter.prevent="inputBlur"
            :spellcheck="false"
        ></textarea>
            <!-- :style="{!edit ? 'display: block' : 'display: none'}"  -->
        <div 
            :style="{display: !edit ? 'block' : 'none'}"
            ref="taskNameContainer"
            :class="{
                'line-clamp-1': $route.params.chat_id && show_task_name_text == 'More',
                'line-clamp-2': !$route.params.chat_id && show_task_name_text == 'More',
            }"
            v-html="urlify(local_name)" 
            @click="onEdit"
        ></div>
        <span @click="show_task_name">{{ show_task_name_text }}</span>
    </div>
    <!-- <div v-else class="task_rename" :style="taskRenameContainerStyle">
        <i class="text-none">{{ local_name }}</i>
            :style="{paddingTop: show_task_name_text != 'More' && $route.params.chat_id ? '8.5px' : ''}"
        <textarea
            v-model="local_name"
            :class="[type == 'task' ? 'task_title inputBlur2' : 'inputBlur2', edit ? 'd-block' : 'task-rename-not-active', {'inside-chat': $route.params.chat_id}]"
            ref="insideChatTextarea"
            @blur="rename_el"
            @keyup.enter.prevent="inputBlur"
            :spellcheck="false"
        ></textarea>
            :style="{!edit ? 'display: block' : 'display: none'}" 
        <div 
            :style="{display: !edit ? 'block' : 'none', paddingTop: show_task_name_text != 'More' ? '8.5px' : '', paddingBottom: show_task_name_text != 'More' && $route.params.chat_id ? '8.5px' : ''}" 
            :class="{
                'line-clamp-1': $route.params.chat_id && show_task_name_text == 'More',
                'line-clamp-2': !$route.params.chat_id && show_task_name_text == 'More',
            }"
            v-html="urlify(local_name)" 
            @click="onEdit"
        ></div>
        <span @click="show_task_name">{{ show_task_name_text }}</span>
    </div> -->
</template>

<script>

    import { mapActions, mapGetters } from "vuex"
    import { EventEmitter } from '../../main'

    export default {
        name: "RanameTask",
        props: {
            id: [String, Number],
            name: [String],
            type: [String],
            pr_ids: [Object, Array]
        },
        data() {
            return {
                edit: false,
                local_name: "",
                local_name_slice: "",
                show_task_name_text: "Less",
                taskRenameContainerStyle: {},
                textareaStyle: {},
                resizeTimeout: ''
            }
        },
        mounted() {
            if (this.$route.params.chat_id) {
                window.addEventListener('resize', this.handleResize)
            }

            EventEmitter.$on('WindowResizeChat', () => {
                clearTimeout(this.resizeTimeout)
                this.resizeTimeout = setTimeout(() => {
                    this.handleResize()
                }, 50)
            })

            this.local_name = this.name

            setTimeout(() => {
                this.textAreaAdjust()
            }, 500)

        },
        methods: {
            ...mapActions(["socket_rename_project", "socket_rename_task"]),
            onEdit(e) {
                
                e.preventDefault()
                const link = e.target.closest("a")
                if (link) {
                    if (link.origin == window.location.origin) {
                        let url = link.href.replace(link.origin, '')
                        this.$router.push(url).catch(()=>{})
                    } else {
                        window.open(link.href, '_blank')
                    }
                }

                const path = e.path ? e.path : e.composedPath()
                if (!path[0].href) { 
                    this.edit = true
                    this.show_task_name_text = ""

                    setTimeout(() => {
                        document.querySelector('.task_rename').classList.add('scroll')
                        document.querySelector('textarea.inputBlur2').focus()
                    }, 0)
                }
            },
            urlify(str) {

                var urlRegex = /(http(s)?:\/\/[^\s]+)/g

                str = str.replace(/</g, '&lt;')
                str = str.replace(/>/g, '&gt;')

                return str.replace(urlRegex, function(url) {
                    return '<div class="inline-p-0"><a href="' + url + '" target="_blank">' + '' + url + '' + '</a></div>'
                })

            },
            checkText() {
                this.local_name = this.local_name.replace("/n", "")
            },
            inputBlur() {
                if (document.querySelector('.inputBlur')) {
                    document.querySelector('.inputBlur').blur()
                }
                if (document.querySelector('.inputBlur2')) {
                    document.querySelector('.inputBlur2').blur()
                }
            },
            show_task_name() {

                if (document.querySelector(".task_rename").classList.contains("task_rename__change")) {
                    document.querySelector(".task_rename").classList.remove("task_rename__change")
                    this.show_task_name_text = "More"
                    document.querySelector('.task_rename').style.height = 'auto'
                    if (this.$route.params.chat_id) {
                        this.handleMinimzedTitle()
                    }
                    // this.edit = false
                } else {
                    if (document.querySelector(".task_rename")) {
                        document.querySelector(".task_rename").classList.add("task_rename__change")
                        this.show_task_name_text = "Less"
                        document.querySelector('.task_rename').classList.add('scroll')
                        document.querySelector('.task_rename').style.height = document.querySelector(".right_sidebar .right_sidebar__title textarea").scrollHeight + 'px'
                        
                        if (this.$route.params.chat_id) {
                            const newHeight = this.getContentHeight()
                            EventEmitter.$emit('onEditingNameChange', {height: newHeight > 47 ? newHeight + 20 + 'px' : '47px'})
                        }
                    }
                    // this.edit = true
                }

                if (this.$route.params.chat_id) {
                    this.handleResize()
                }
                let winHeight = window.innerHeight
                // setTimeout(() => {
                //     document.querySelector("#right_sidebar__bottom").style.height = winHeight - document.querySelector(".right_sidebar__top").offsetHeight + "px"
                // }, 0)
            
            },
            textAreaAdjust() {

                if (this.type != "project") {
                    if (document.querySelector(".task_rename")) {
                        document.querySelector(".task_rename").classList.add("task_rename__ellips")
                    }

                    let textarea = document.querySelector(".right_sidebar .right_sidebar__title textarea")

                    if (textarea) {
                        textarea.style.height = "1px"
                        textarea.style.height = textarea.scrollHeight + "px"
                    }

                    if (document.querySelector(".task_rename div")) {
                        let text_none_width = document.querySelector(".text-none").clientWidth / 2
                        let text_height = document.querySelector(".task_rename div").clientHeight
                        let text_width = document.querySelector(".task_rename div").clientWidth
                        let textareHeight = document.querySelector('.inputBlur2').clientHeight

                        this.show_task_name_text = "Less"

                        if (text_height >= 60) {
                            if (text_none_width + 50 > text_width) {
                                document.querySelector(".task_rename").classList.remove("task_rename__ellips")
                                this.show_task_name_text = "More"
                            }
                        }

                        if (this.$route.params.chat_id) {
                            if (textareHeight > 47) {
                                this.show_task_name_text = "More"
                                document.querySelector(".task_rename").classList.remove("task_rename__ellips")
                            } else {
                                document.querySelector(".task_rename").classList.add("task_rename__ellips")
                                this.show_task_name_text = "Less"
                            }
                        }
                    }

                    let winHeight = window.innerHeight

                    // document.querySelector("#right_sidebar__bottom").style.height = winHeight - document.querySelector(".right_sidebar__top").offsetHeight + "px"
                
                }

            },
            rename_el() {

                if (this.local_name != '') {

                    this.edit = false
                    // document.querySelector('.task_rename').classList.remove('scroll')
                    if (document.querySelector(".task_rename")) {
                        document.querySelector(".task_rename").classList.remove("task_rename__change")
                        document.querySelector('.task_rename').style.height = 'auto'
                    }
                    this.show_task_name_text = "More"
                    
                    this.local_name = this.local_name.replace(/[\r\n\v]+/g, "")

                    // let textarea = document.querySelector(".right_sidebar .right_sidebar__title textarea")

                    // if (textarea) {
                    //     textarea.style.height = "1px"
                    //     textarea.style.height = textarea.scrollHeight + "px"
                    // }

                    // if (document.querySelector("#right_sidebar__bottom")) {
                    //     let winHeight = window.innerHeight
                    //     document.querySelector("#right_sidebar__bottom").style.height = winHeight - document.querySelector(".right_sidebar__top").offsetHeight + "px"
                    // }

                    if (this.local_name != this.name) {

                        this.local_name = this.local_name.slice(0, 160)
                        this.textAreaAdjust()

                        if (this.type == "project") {
                            this.socket_rename_project({
                                name: this.local_name.trim() != "" ? this.local_name.trim() : "Untitled",
                                _id: this.id
                            })
                        } else if (this.type == "task" && this.pr_ids) {

                            this.pr_ids.forEach((pr_id) => {
                                this.socket_rename_task({
                                    name: this.local_name.trim() != "" ? this.local_name.trim() : "Untitled",
                                    task_id: this.id,
                                    project_id: pr_id
                                })
                            })

                            this.$emit("rename_chat", this.local_name)

                        }

                    }
                } else {
                    this.local_name = this.name
                }
            },
            handleMinimzedTitle() {
                EventEmitter.$emit('onEditingNameChange', {height: '47px'})
            },
            removeMoreBtnIfContentFits() {
                const parent = document.getElementById('right_sidebar__top') 
                const element =  document.createElement('div')

                if (!element) return
                if (!this.$refs.taskNameContainer) return

                element.classList.add('hidden-task-name')
                element.innerHTML = this.$refs.taskNameContainer.innerHTML
                parent.appendChild(element)
                const newHeight = element.clientHeight
                const currentContentHeight = this.$refs.taskNameContainer.clientHeight
                parent.removeChild(element)
                if (newHeight == currentContentHeight && currentContentHeight <= 30) {
                    document.querySelector('.chat-rename-wrapper').classList.add('task_rename__ellips')
                } else {
                    document.querySelector('.chat-rename-wrapper').classList.remove('task_rename__ellips')
                }
            },
            getContentHeight() {
                const parent = document.getElementById('right_sidebar__top') 
                const element =  document.createElement('div')

                if (!element) return
                if (!this.$refs.taskNameContainer) return

                const taskNameElement = this.$refs.taskNameContainer
                element.classList.add('hidden-task-name')
                element.innerHTML = taskNameElement.innerHTML
                element.style.width = taskNameElement.clientWidth - 75 + 'px'
                parent.appendChild(element)
                const newHeight = element.clientHeight
                parent.removeChild(element)
                return newHeight
            },
            handleResize() { 
                this.removeMoreBtnIfContentFits()

                if (this.show_task_name_text.toLowerCase() == 'more') return
                
                const newHeight = this.getContentHeight()

                EventEmitter.$emit('onEditingNameChange' , {height: newHeight > 47 ? newHeight + 20 + 'px' : '47px'})
                this.taskRenameContainerStyle = {height: newHeight > 47 ? newHeight + 'px' : '47px'}
                const textareaHeight = this.taskRenameContainerStyle.height.replace('px', '')
                this.textareaStyle = {height: textareaHeight + 'px'}
                let winHeight = window.innerHeight
                // setTimeout(() => {
                //     if (document.querySelector("#right_sidebar__bottom")) {
                //         document.querySelector("#right_sidebar__bottom").style.height = winHeight - document.querySelector(".right_sidebar__top").offsetHeight + "px"
                //     }
                // }, 0)
            }
        },
        computed: {
            ...mapGetters(["getTasks", "getProjects"]),
            get_name() {
                return this.name
            }
        },
        watch: {
            edit(newValue) {
                if (newValue == true) {
                    const newHeight = this.getContentHeight()
                    if (this.$route.params.chat_id) {
                        this.taskRenameContainerStyle = {height: newHeight > 47 ? newHeight + 20 + 'px' : '30px'}
                        const textareaHeight = this.taskRenameContainerStyle.height.replace('px', '') - 20
                        this.textareaStyle = {height: textareaHeight == 30 ? '30px' : textareaHeight + 'px'}
                    }

                } else if (this.$route.params.chat_id) {
                    this.taskRenameContainerStyle = {height: '30px'}
                    this.textareaStyle = {height: '30px'}
                }
                setTimeout(() => {

                    // if (document.querySelector(".right_sidebar .right_sidebar__title textarea")) {
                    //     let textarea = document.querySelector(".right_sidebar .right_sidebar__title textarea")

                    //     if (textarea) {
                    //         textarea.style.height = "1px"
                    //         textarea.style.height = textarea.scrollHeight + "px"
                    //     }
                    // }

                    let winHeight = window.innerHeight
                    if (this.$route.params.chat_id) {
                        const newHeight = this.getContentHeight()
                        const styles = newValue ? {height: newHeight > 47 ? newHeight + 20 + 'px' : '47px'} : {height: '47px'}
                        EventEmitter.$emit('onEditingNameChange', styles)
                    }
                    // setTimeout(() => {
                    //     document.querySelector("#right_sidebar__bottom").style.height = winHeight - document.querySelector(".right_sidebar__top").offsetHeight + "px"
                    // }, 0)
                }, 0)
            },
            get_name(e) {

                this.local_name = e

                setTimeout(() => {
                    this.textAreaAdjust()
                }, 100)

            },
            $route() {

                if (document.querySelector(".task_rename")) {
                    document.querySelector(".task_rename").classList.remove("task_rename__change")
                    document.querySelector('.task_rename').classList.remove('scroll')
                    // document.querySelector(".task_rename").classList.remove("task_rename__ellips")
                    document.querySelector('.task_rename').style.height = 'auto'
                    this.show_task_name_text = "More"
                }

                // if (document.querySelector(".task_rename")) {
                //     document.querySelector(".task_rename").classList.remove("task_rename__change")
                // }

                // if (document.querySelector("#right_sidebar__bottom")) {
                //     let winHeight = window.innerHeight
                //     document.querySelector("#right_sidebar__bottom").style.height = winHeight - document.querySelector(".right_sidebar__top").offsetHeight + "px"
                // }

                setTimeout(() => {
                    this.textAreaAdjust()
                }, 100)

            },
            local_name(e) {

                if (e) {

                    if (this.local_name) {
                        this.local_name = this.local_name.replace(/[\r\n\v]+/g, "")
                    }

                    if (e.length > 160) {
                        this.local_name = e.slice(0, 160)
                    }

                    let textarea = document.querySelector(".right_sidebar .right_sidebar__title textarea")

                    if (textarea) {
                        textarea.style.height = "1px"
                        textarea.style.height = textarea.scrollHeight + "px"
                    }

                    // if (document.querySelector("#right_sidebar__bottom")) {
                    //     let winHeight = window.innerHeight
                    //     document.querySelector("#right_sidebar__bottom").style.height = winHeight - document.querySelector(".right_sidebar__top").offsetHeight + "px"
                    // }

                }
            }
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.handleResize)
        }
    }

</script>

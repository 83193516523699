<template>
    <div class="comment_other_actions comment_other_actions--chat hidden" ref="other_action">
        <span
            v-if="selectedMessage && selectedMessage.user_id == getUserData._id"
            class="other_action"
            @click="edit_message(selectedMessage)"
        >
            Edit
        </span>
        <span class="other_action" @click="reply_message(selectedMessage)">
            Reply
        </span>
        <span class="other_action" @click="copy_message(selectedMessage)">
            Copy
        </span>
        <span class="other_action" v-if="selectedMessage && selectedMessage.user_id == getUserData._id" @click="delete_message(selectedMessage)">
            Delete
        </span>
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from "vuex"
    import File from '../../utils/aws'
    import {EventEmitter} from '../../main'

    export default {
        name: "MenuAction",
        data() {
            return {
                selectedMessage: null,
                edit: '',
                reply: ''
            }
        },
        mounted() {
            this.$root.$on("OpenMenuAction", ({ comment, position = null }) => {
                this.show_popup(comment, position)
            })
            document.addEventListener('click', () => {
                if (this.$refs.other_action)
                {
                    if (this.$refs.other_action.classList.contains('active')) {
                        this.$refs.other_action.classList.add("hidden")
                    }
                }
            })
        },
        methods: {
            ...mapMutations([
                "setUserFileSize_minus"
            ]),

            edit_message(comment) {
                EventEmitter.$emit('edit_message', comment)
                this.$refs.other_action.classList.add("hidden")
            },
            reply_message(comment) {
                EventEmitter.$emit('reply_message', comment)
                this.$refs.other_action.classList.add("hidden")
            },
            copy_message(comment) {
                this.$clipboard(comment.message);
                this.$refs.other_action.classList.add("hidden")
            },
            delete_message(comment) {

                let delete_message_popup

                const aws = new File()
                if (comment.files) {
                    comment.files.forEach((e) => {
                        this.setUserFileSize_minus(+e.size);
                        if (e.ext.toLowerCase() == 'png' || e.ext.toLowerCase() == 'jpg' || e.ext.toLowerCase() == 'jpeg') {

                            if (e.imgWidth > 200) {
                                let sizes = [200, 'full']
                                sizes.forEach((size) => {
                                    const remove = aws.remove('Chats' + '/' + this.$route.params.chat_id + '/' + size + '__' + e._id + '__' + e.filename)
                                }) 
                            } else {
                                const remove = aws.remove('Chats' + '/' + this.$route.params.chat_id + '/' + e._id + '__' + e.filename)
                            }

                        } else {
                            const remove = aws.remove('Chats' + '/' + this.$route.params.chat_id + '/' + e._id + '__' + e.filename)
                        }
                    })
                }

                if (document.querySelector(".custom_message_popup"))
                    delete_message_popup = document.querySelector(".custom_message_popup")
                else {
                    delete_message_popup = document.createElement("div")
                    delete_message_popup.classList.add("custom_message_popup")
                    this.$root.$el.insertAdjacentElement("beforeend", delete_message_popup)
                }

                delete_message_popup.innerHTML = ""
                const body = document.createElement("div")
                delete_message_popup.insertAdjacentElement("beforeend", body)
                const title = document.createElement("h4")
                title.innerHTML = "Do you want to delete this comment?"
                body.insertAdjacentElement("beforeend", title)
                const call_to_action_footer = document.createElement("div")
                call_to_action_footer.classList.add("call_to_action_footer")
                body.insertAdjacentElement("beforeend", call_to_action_footer)
                const cancel = document.createElement("button")
                cancel.innerHTML = "Cancel"
                call_to_action_footer.insertAdjacentElement("beforeend", cancel)
                const confirm = document.createElement("button")
                confirm.innerHTML = "Delete"
                call_to_action_footer.insertAdjacentElement("beforeend", confirm)

                delete_message_popup.classList.add("active")

                cancel.addEventListener("click", (e) => {
                    e.preventDefault()
                    delete_message_popup.classList.remove("active")
                })

                confirm.addEventListener("click", (e) => {
                    e.preventDefault()
                    delete_message_popup.classList.remove("active")
                    // this.delete_message(comment)
                    this.$socket.emit('IN_RemoveMessages', this.$route.params.chat_id, this.selectedMessage._id)
                })
                
            },

            show_popup(comment, position) {
                let $this = this;
                $this.selectedMessage = null

                if ($this.$refs.other_action) {
                    if (comment) {
                      $this.$refs.other_action.classList.add("active")
                        setTimeout(() => {
                          $this.$refs.other_action.classList.remove("hidden")
                        }, 100)
                    } else {
                      $this.$refs.other_action.classList.remove("active")
                      $this.$refs.other_action.classList.add("hidden")
                    }
                }

              $this.selectedMessage = comment

                if (position) {
                    // console.log(position)

                    setTimeout(() => {
                      console.log('position.chatWindow.width', 'position.chatWindow.width')
                      console.log(position.mouseX, position.chatWindow.width)
                        if ((position.mouseX + 170) > position.chatWindow.width) {
                          $this.$refs.other_action.style.left = 'unset'
                          $this.$refs.other_action.style.right = position.chatWindow.width - position.mouseX + 'px'
                        } else {
                          $this.$refs.other_action.style.right = 'unset'
                          $this.$refs.other_action.style.left = position.mouseX + 'px'
                        }

                      if (position.mouseY - $this.$refs.other_action.clientHeight > position.chatWindow.height)
                      {
                        $this.$refs.other_action.style.bottom = '0px'
                        $this.$refs.other_action.style.top = 'unset'
                      }
                      else
                      {
                        $this.$refs.other_action.style.bottom = 'unset'
                        $this.$refs.other_action.style.top = position.mouseY + 'px'
                      }
                    }, 100)
                }
            },
        },
        computed: {
            ...mapGetters(["getUserData"]),
        },
    }

</script>

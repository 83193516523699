<template>
    <div class="error_req" :style="`width: ${width}px; height: ${width}px`">
        <font-awesome-icon :icon="['fas', 'exclamation-circle']" />
    </div>
</template>

<script>

    export default {
        props: {
            width: [Number]
        }
    }

</script>

<template>
  <button @click="goBack" class="chats-top-back">
    <img :src="require(`@/assets/static/images/arrow_back.svg`)" alt="">
  </button>
</template>
<script>
export default {
  methods: {
    goBack() {
      return this.$router.go(-1)
    }
  }
}
</script>
<template>
    <div class="comments_block" ref="comments_block" id="comments_block" @scroll="drawDateSticky">
        <span class="task_created">
            {{ create_date }}
        </span>
        <div class="comment_date date_sticky" ref="date_sticky">
            <span></span>
        </div>
        <div ref="messages" v-if="messages && messages.length > 0">
            <div v-for="comment in messages" :key="comment._id" :id="`comment_${comment._id}`" :class="comment.class ? comment.class + ' ' + blockClass(comment.user_id, resize) : blockClass(comment.user_id, resize)">
                <div v-if="comment.date_for_chat" class="comment_date">
                    <span>{{ comment.date_for_chat }}</span>
                </div>
                <div :class="comment.avatar ? 'comment_body' : 'comment_body avatar_none'">
                    <div class="comment_body_avatar" :style="'background-color:' + comment.user.color" v-html="$w_user_image(comment.user)">
                        {{ comment.avatar }}
                    </div>
                    <div class="comment_text" :style="`width: ${comment.width}px`" :class="comment.user_id != getUserData._id ? 'white' : 'green'" @contextmenu.prevent="showOtherActions(comment)">
                        <ul class="comment_read_user" v-if="getPlan.show_who_read_message == 'on' && comment.user_id == getUserData._id">
                            <li v-for="user of users" :key="user.user_id">
                                <div v-if="user.read_last_message == comment._id && getUserData._id != user.user_id" :style="'background-color:' + user.user_data.color" v-html="$w_user_image(user.user_data, 70, false)"></div>
                            </li>
                        </ul>
                        <div v-if="comment.user_id != getUserData._id" class="w-100 friends__comments">
                            <span v-if="!comment.hide" class="comment_text__user_name" :style="'color:' + comment.user.color + ' !important'">
                                {{ comment.user.name }}
                            </span>
                            <div v-if="comment.reply" class="comment_text__reply" @click="goToMessage(comment.reply)">
                                <span class="comment_text__user_name reply__name">
                                    {{ comment.reply.user.name }}
                                </span>
                                <div v-if="comment.reply.files.length > 0" class="comment_text__body reply__body">
                                    {{ comment.reply.files[0].filename }}
                                </div>
                                <div v-else class="comment_text__body reply__body">
                                    {{ comment.reply.message }}
                                </div>
                            </div>
                        </div>
                        <div v-else class="w-100">
                            <div v-if="comment.reply" class="comment_text__reply" @click="goToMessage(comment.reply)">
                                <span v-if="comment.reply.user_id == getUserData._id" class="comment_text__user_name reply__name green-name">
                                    {{ comment.reply.user.name }}
                                </span>
                                <span v-else class="comment_text__user_name reply__name">
                                    {{ comment.reply.user.name }}
                                </span>
                                <div v-if="comment.reply.files.length > 0" class="comment_text__body reply__body" :ref="'reply_msg_' + comment._id">
                                    {{ comment.reply.files[0].filename }}
                                </div>
                                <div v-else class="comment_text__body reply__body" :ref="'reply_msg_' + comment._id">
                                    {{ comment.reply.message }}
                                </div>
                            </div>
                        </div>

                        <div v-if="comment.files && comment.files.length > 0" class="comment_text__file attachment">
                            <div
                                v-for="file_body in comment.files"
                                :key="file_body._id"
                                :id="`file_${file_body._id}`"
                                :ref="`file_${file_body._id}`"
                                class="comment_text__file_body attachment_body"
                            >
                                <DocFile
                                    :file_body="file_body"
                                    :src="'chat'"
                                    :date="comment.date"
                                    :user_name="comment.user.name"
                                    :comment_id="comment._id"
                                    :type="'Chat'"
                                />
                            </div>
                        </div>

                        <div class="comment_text__body" :ref="'msg_' + comment._id">
                            <div v-if="comment.message" style="margin-top: 3px">
                                <span v-html="addRevert(comment.message)"></span>
                                <div class="block" :style=" getUserData.date_format.time == '1:00 PM' ? 'width: 57px;' : 'width: 44px;'"></div>
                            </div>

                            <span class="comment_text__time" v-if="comment.files && comment.files.length > 0">
                                <span class="bg-color">
                                    {{$w_time_parse(comment.date, getUserData.date_format.time)}}
                                    <svg v-if="comment.send" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0 0 24 24" style="fill: #ffffff">
                                        <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 6 L 11 12.414062 L 15.292969 16.707031 L 16.707031 15.292969 L 13 11.585938 L 13 6 L 11 6 z"></path>
                                    </svg>
                                    <span v-else>
                                        <i v-if="comment.user_id == getUserData._id" class="el-icon-check"></i>
                                        <i v-if="comment.read" class="el-icon-check readed_message"></i>
                                    </span>
                                </span>
                            </span>
                            <span class="comment_text__time" v-else>
                                <span class="">
                                    {{$w_time_parse(comment.date, getUserData.date_format.time)}}
                                    <svg v-if="comment.send" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0 0 24 24" style="fill: #a5a7a8">
                                        <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 6 L 11 12.414062 L 15.292969 16.707031 L 16.707031 15.292969 L 13 11.585938 L 13 6 L 11 6 z"></path>
                                    </svg>
                                    <span v-else>
                                        <i v-if="comment.user_id == getUserData._id" class="el-icon-check"></i>
                                        <i v-if="comment.read" class="el-icon-check readed_message"></i>
                                    </span>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {mapGetters, mapMutations} from "vuex"
    import DocFile from "../comments/DocFile"
    import { EventEmitter } from "../../main"

    export default {
        props: {
            messages: [Array],
            create_date: [String],
            users: [Array]
        },
        data() {
            return {
                resize: false,
                curUserName: ""
            }
        },
        components: {
            DocFile
        },
        computed: {
            ...mapGetters([
                "getChats", 
                "getUserData", 
                "getPlan"
            ]),
            ...mapMutations([
                "setJumpToMessage"
            ])
        },
        mounted() {

            // EventEmitter.$on('sendMessageChat', (data) => {
            //     if (this.$route.params.chat_id == data.chat_id) {
            //         // this.messages.push(data.messages)
            //     }
            // })

            EventEmitter.$on('sendMessageChatPanelStatus', (status) => {
                this.resize = status
            })

            document.addEventListener("mousemove", function (e) {
                if (document.getElementById("comments_block")) {
                    let ele = document.getElementById("comments_block")
                    let distance = 232 + ele.offsetWidth - e.pageX
                    distance < 15 && distance > -15 ? ele.classList.add("more-width") : ele.classList.remove("more-width")
                }
            })

            document.getElementById("comments_block").addEventListener("scroll", function (e) {
                let ele = document.getElementById("comments_block")
                ele.classList.add("more-width")
                setTimeout(() => {
                    ele.classList.remove("more-width")
                }, 1500)
            })

            window.addEventListener("resize", (e) => {
                if (document.body.clientWidth >= 1366) {
                    this.resize = true
                } else {
                    this.resize = false
                }
            })

            if (document.body.clientWidth >= 1366) {
                this.resize = true
            } else {
                this.resize = false
            }

        },
        methods: {
            showVar(e) {
                console.log(e)
            },
            goToMessage(reply) {
                if (reply._id) {

                    const comments_block = this.$refs.comments_block
                    const comment = document.getElementById(`comment_${reply._id}`)

                    if (comment) {

                        comment.querySelector(".comment_text").classList.add("hover")

                        setTimeout(() => {

                            comments_block.scroll({
                                left: 0,
                                top: comment.offsetTop - 75,
                                behavior: "smooth"
                            })

                            this.setJumpToMessage(null)
                            this.toScroll = true

                        }, 10)

                        setTimeout(() => {
                            comment.querySelector(".comment_text").classList.remove("hover")
                        }, 3000)

                    } else {

                        this.setJumpToMessage(null)
                        this.setMessage({
                            key_message: "warning",
                            message: "Sorry, there is no such message."
                        })

                    }

                }
            },
            blockClass(user_id, resize) {
                if (resize) {
                    return "comment comment_user comment_guest"
                } else {
                    return user_id == this.getUserData._id ? "comment comment_user" : "comment comment_guest"
                }
            },
            addRevert(msg) {
                return msg.replaceAll('\n', "<br />")
            },
            drawDateSticky(e) {

                const date_sticky = this.$refs.date_sticky
                const span_date_sticky = date_sticky.querySelector("span")
                const scrollTop = e.target.scrollTop
                const dates = e.target.querySelectorAll(".comment_date")
                const padding = 3
                const higher_dates = []

                date_sticky.classList.add("active")

                dates.forEach((date, dateIdx, dateArr) => {

                    const parentTop = date.parentElement.offsetTop - 70

                    if (!date.classList.contains("date_sticky") && scrollTop > parentTop) {
                        date.classList.add("disable")

                        let shift = padding

                        if (dateArr[dateIdx + 1]) {
                            const afterItem = dateArr[dateIdx + 1]
                            const parentTopAfterItem = afterItem.parentElement.offsetTop
                            shift = scrollTop + date_sticky.offsetHeight + padding * 2 >= parentTopAfterItem ? (date_sticky.offsetHeight - (parentTopAfterItem - scrollTop) + padding * 3) * -1 : padding
                        }

                        higher_dates.push({
                            date: date.querySelector("span").innerHTML,
                            shift
                        })

                    } else {
                        date.classList.remove("disable")
                    }

                })

                if (higher_dates.length > 0) {
                    span_date_sticky.innerHTML = higher_dates[higher_dates.length - 1].date
                    date_sticky.style = `transform: translate(0, ${higher_dates[higher_dates.length - 1].shift}px)`
                } else {
                    date_sticky.classList.remove("active")
                }

            },
            showOtherActions(msg) {

                const e = event

                setTimeout(() => {
                    this.$root.$emit("OpenMenuAction", {
                        comment: msg,
                        position: {
                            mouseX: e.pageX - 230,
                            mouseY: e.pageY - 30,
                            chatWindow: {
                                top: document.body.clientHeight - this.$refs.comments_block.nextElementSibling.clientHeight - this.$refs.comments_block.clientHeight,
                                right: document.body.clientWidth,
                                left: document.body.clientWidth - this.$refs.comments_block.clientWidth,
                                bottom: document.body.clientHeight - this.$refs.comments_block.nextElementSibling.clientHeight,
                                height: this.$refs.comments_block.clientHeight,
                                width: this.$refs.comments_block.clientWidth
                            },
                            window: {
                                height: document.body.clientHeight,
                                width: document.body.clientWidth
                            }
                        }
                    })
                }, 100)

            }
        },
        // watch: {
        //     getRender() {
        //         this.messages =
        //     }
        // }
    }

</script>
